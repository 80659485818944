import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Input from '../../common/components/form/Input';
import Button from '../../common/components/Button';
import Ajax from '../../common/ajax';
import Config from '../../config';
import Col from '../../common/containers/Col';
import Row from '../../common/containers/Row';
import LocaleUtils from '../../common/LocaleUtils';

class DiscountField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCupom: false,
      discount_code: '',
      error: '',
      discountedPrice: null,
    };
    this.toggleField = this.toggleField.bind(this);
    this.onChange = this.onChange.bind(this);
    this.apply = this.apply.bind(this);
    this._jqXHR = null;
  }

  componentWillUnmount() {
    if (this._jqXHR) {
      this._jqXHR.abort();
    }
  }

  onChange(e) {
    e.persist();
    this.setState((s) => ({ ...s, discount_code: e.target.value }));
  }

  toggleField() {
    this.setState((s) => ({ ...s, showCupom: !this.state.showCupom }));
  }

  apply() {
    this.props.onProcessing && this.props.onProcessing(true);
    const url = `${Config.apiHost}plans/${this.props.plan.id}/discounted_value/?discount_code=${this.state.discount_code}`;
    Ajax.get(url)
      .done((discountedPrice) => {
        this.setState((s) => ({ ...s, discountedPrice }));
        this.props.onProcessing && this.props.onProcessing(false);
        this.props.onApply(this.state.discount_code, discountedPrice);
      })
      .fail((jqXHR) => {
        this.setState((s) => ({ ...s, error: jqXHR.responseJSON.detail }));
        this.props.onProcessing && this.props.onProcessing(false);
      });
  }

  getPriceLabel() {
    const { i18n } = this.props;
    let priceLabel;

    if (this.state.discountedPrice) {
      priceLabel = (
        <span>
          <span>
            {this.props.currencyLabel} {LocaleUtils.number(this.state.discountedPrice, 2)}
          </span>{' '}
          <small>
            <strike>
              {this.props.currencyLabel} {LocaleUtils.number(this.props.plan.final_price, 2)}
            </strike>
          </small>
        </span>
      );
    } else {
      if (this.props.plan.final_price < this.props.plan.price) {
        priceLabel = (
          <span>
            <span>
              {this.props.currencyLabel} {LocaleUtils.number(this.props.plan.final_price, 2)}
            </span>{' '}
            <small>
              <strike>
                {this.props.currencyLabel} {LocaleUtils.number(this.props.plan.price, 2)}
              </strike>
            </small>
          </span>
        );
      } else {
        priceLabel = (
          <span>
            {this.props.currencyLabel} {LocaleUtils.number(this.props.plan.final_price, 2)}
          </span>
        );
      }
    }

    return (
      <h3>
        {i18n.t('Plano')} {this.props.plan.name} {priceLabel}
      </h3>
    );
  }

  render() {
    const { i18n } = this.props;
    let icon;

    if (this.state.showCupom) {
      icon = 'mdi-minus-circle-outline';
    } else {
      icon = 'mdi-plus-circle-o';
    }

    const canApplyCupom = !this.state.discountedPrice && this.props.plan.price === this.props.plan.final_price;

    return (
      <>
        {this.getPriceLabel()}

        {canApplyCupom && (
          <Row>
            <Col md={6}>
              <p>
                <a role="button" onClick={this.toggleField}>
                  <i className={`icon mdi ${icon}`} /> {i18n.t('Cupom de desconto')}
                </a>
              </p>
              {this.state.showCupom && (
                <Input
                  id="id_discount_code"
                  type="text"
                  name="discount_code"
                  value={this.state.discount_code}
                  label=""
                  autoComplete="fuck-off"
                  maxLength="500"
                  error={[this.props.errors['discount_code'], this.state.error]}
                  onChange={this.onChange}
                  buttonAddOn={
                    <Button type="default" onClick={this.apply}>
                      {i18n.t('Aplicar')}
                    </Button>
                  }
                />
              )}
            </Col>
          </Row>
        )}

        {!canApplyCupom && <br />}
      </>
    );
  }
}

DiscountField.propTypes = {
  /**
   * A function which receives the discountCode and the discountedPrice.
   */
  onApply: PropTypes.func.isRequired,
  onProcessing: PropTypes.func,
  /**
   * The object of errors returned by the backend.
   */
  errors: PropTypes.object,
  plan: PropTypes.object,
  currencyLabel: PropTypes.string,
};

DiscountField.defaultProps = {
  errors: {},
  currencyLabel: 'R$',
};

export default withNamespaces()(DiscountField);
